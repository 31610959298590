// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-opt-in-out-opt-in-out-styles__optInOut--1NKUW {\n  z-index: 109995;\n  position: fixed;\n  bottom: 12px;\n  right: 14px;\n}\n\n.src-opt-in-out-opt-in-out-styles__bubble--k4kkF {\n  border: 0;\n  padding: 0;\n  background-color: var(--cps-color-vibrant-ocean);\n  height: 32px;\n  width: 32px;\n  border-radius: 8px;\n}\n\n.src-opt-in-out-opt-in-out-styles__flexCenter--3YXkG {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.src-opt-in-out-opt-in-out-styles__countNumber--1e-sj {\n  font-size: 12px;\n  background-color: var(--cps-color-mandy);\n  color: var(--cps-color-bumble);\n  width: 16px;\n  height: 16px;\n  border: 1px solid var(--cps-color-bumble);\n  position: absolute;\n  top: -8px;\n  right: -8px;\n  border-radius: 50%;\n}\n\n.src-opt-in-out-opt-in-out-styles__bubbleWrapper--2VMKs {\n  float: right;\n}\n", ""]);
// Exports
exports.locals = {
	"optInOut": "src-opt-in-out-opt-in-out-styles__optInOut--1NKUW",
	"bubble": "src-opt-in-out-opt-in-out-styles__bubble--k4kkF",
	"flexCenter": "src-opt-in-out-opt-in-out-styles__flexCenter--3YXkG",
	"countNumber": "src-opt-in-out-opt-in-out-styles__countNumber--1e-sj",
	"bubbleWrapper": "src-opt-in-out-opt-in-out-styles__bubbleWrapper--2VMKs"
};
module.exports = exports;
