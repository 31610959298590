import React from 'react'
import ReactDOM from 'react-dom'
import OptInOut from './opt-in-out.component.js'

// We render the root component immediately. It will show nothing
// until someone actually renders a toast, but will set up a subscription
// to all of the featureBubble function calls.
const containerDiv = document.createElement('div')
containerDiv.id = 'opt-in-out-container'
document.body.appendChild(containerDiv)
ReactDOM.render(<OptInOut />, containerDiv)
