// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-opt-in-out-bubbles-list-styles__bubblesList--2yIA1 {\n  position: relative;\n  margin: 8px 0;\n  padding: 16px 24px;\n  width: 360px;\n  overflow-y: auto;\n}\n\n.src-opt-in-out-bubbles-list-styles__closeIcon--1W_uz {\n  position: absolute;\n  right: 12px;\n  top: 12px;\n}\n\n.src-opt-in-out-bubbles-list-styles__oldVersion--SMsMO {\n  text-decoration: underline;\n}\n\n.src-opt-in-out-bubbles-list-styles__actionBlock--2sHKV {\n  display: flex;\n  justify-content: space-between;\n}\n\n.src-opt-in-out-bubbles-list-styles__actionBlock--2sHKV .src-opt-in-out-bubbles-list-styles__primaryAction--e03JR {\n  margin: 0 auto 8px auto;\n  text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"bubblesList": "src-opt-in-out-bubbles-list-styles__bubblesList--2yIA1",
	"closeIcon": "src-opt-in-out-bubbles-list-styles__closeIcon--1W_uz",
	"oldVersion": "src-opt-in-out-bubbles-list-styles__oldVersion--SMsMO",
	"actionBlock": "src-opt-in-out-bubbles-list-styles__actionBlock--2sHKV",
	"primaryAction": "src-opt-in-out-bubbles-list-styles__primaryAction--e03JR"
};
module.exports = exports;
