import React, { useEffect, useState, useCallback, Suspense } from 'react'
import styles from './opt-in-out.styles.css'
import { bubblesStream } from './opt-in-out.js'
import { setBubbleList, getBubbleList } from './opt-in-out.storage.js'
import BubblesList from './bubbles-list.component.js'
const CprIcon = React.lazy(() => SystemJS.import('canopy-styleguide!sofe').then(sg => Promise.resolve({ default: sg.CprIcon })))

export default function OptInOut(props) {
  const [featureBubbles, setFeatureBubbles] = useState([])
  const [weHaveNewFeatureBubbles, setWeHaveNewFeatureBubbles] = useState(false)
  const [showBubbles, setShowBubbles] = useState(false)
  const isLoginPage = /login|logout.*redirect_url/.test(window.location.hash)

  const close = useCallback(() => {
    setBubbleList(featureBubbles)
    setShowBubbles(false)
  }, [featureBubbles])

  const handleShowBubbles = useCallback(() => {
    if (showBubbles) {
      setBubbleList(featureBubbles)
    }

    setShowBubbles(!showBubbles)
  }, [showBubbles, featureBubbles])

  useEffect(() => {
    const sub = bubblesStream
      .subscribe(
        (bubbles) => {
          setFeatureBubbles([...bubbles])
        }
      )
    return () => {
      sub.unsubscribe()
    }
  }, [bubblesStream])

  getBubbleList().then((result) => {
    const stuff = JSON.stringify(featureBubbles) === JSON.stringify(result)

    setWeHaveNewFeatureBubbles(!stuff);
  })

  if (featureBubbles.length === 0 || isLoginPage) {
    return null
  } else {
    return (
      <div className={styles.optInOut}>
        <div>
          {
            showBubbles ? (
              <BubblesList bubbles={featureBubbles} close={close} />
            ) : null
          }
          <div className={styles.bubbleWrapper}>
            <button onClick={handleShowBubbles} className={`${styles.bubble} ${styles.flexCenter}`}>
              <div className='cps-color-bumble'>
                <Suspense fallback={null}>
                  <CprIcon name='misc-gift-box' size='24' />
                </Suspense>
              </div>
            </button>
            {
              !showBubbles && weHaveNewFeatureBubbles ? (
                <div className={`${styles.countNumber} ${styles.flexCenter}`}>
                  {featureBubbles.length}
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    )
  }
}
