import moment from 'moment';
import { notifyAnalytics } from '../analytics.resources';

export const changePreference = async (toggle, enabled = true, strategy = 'opt-out', days = 1) => {
  if (!toggle)
    throw Error(
      'Opt-in/opt-out requires a feature toggle to set user preferences'
    );

  const { user } = await getLoggedInUser();
  let userId = user && user.id ? user.id : undefined;
  const isOptOut = strategy === 'opt-out';

  const key = `${isOptOut ? 'optout' : 'optin'}:${toggle}${userId ? `:${userId}` : ''}`;

  if ((isOptOut && enabled) || (!isOptOut && !enabled)) {
    window.localStorage.removeItem(key);
  } else {
    const date = moment.utc().add(days, 'days');

    window.localStorage.setItem(key, isOptOut ? date.format('YYYY-MM-DD') : true);
  }

  notifyAnalytics(user, toggle, { enabled, days }, isOptOut);
};

export const getPreference = async (toggle, strategy) => {
  const { user } = await getLoggedInUser();
  let userId = user && user.id ? user.id : undefined;
  const isOptOut = strategy === 'opt-out';

  const key = `${isOptOut ? 'optout' : 'optin'}:${toggle}${userId ? `:${userId}` : ''}`;

  if (isOptOut) {
    let disabledUntil = window.localStorage.getItem(key);

    if (disabledUntil) {
      if (
        moment
          .utc()
          .startOf('day')
          .diff(moment(disabledUntil), 'days') < 0
      ) {
        return false;
      } else {
        window.localStorage.removeItem(key);
        return true;
      }
    } else {
      return true;
    }
  } else { // opt-in
    return !!window.localStorage.getItem(key);
  }
};

export const setBubbleList = async (bubbleList) => {
  const { user } = await getLoggedInUser();
  let userId = user && user.id ? user.id : undefined;

  const key = `${userId ? `${userId}` : ''}-bubbleList`;

  window.localStorage.setItem(key, JSON.stringify(bubbleList));
}

export const getBubbleList = async () => {
  const { user } = await getLoggedInUser();
  let userId = user && user.id ? user.id : undefined;

  const key = `${userId ? `${userId}` : ''}-bubbleList`;

  return JSON.parse(window.localStorage.getItem(key));
}

async function getLoggedInUser() {
  const auth = await SystemJS.import('cp-client-auth!sofe');
  const { of } = await SystemJS.import('rxjs');
  const { timeoutWith, first } = await SystemJS.import('rxjs/operators');

  const user = await auth.default
    .getLoggedInUserAsObservable()
    .pipe(first(), timeoutWith(500, of(null)))
    .toPromise();

  return { user };
}
