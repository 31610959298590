import canopyUrls from 'canopy-urls!sofe';

export function nameForCurrentEnv() {
  switch (canopyUrls.getEnvironment()) {
    case canopyUrls.PRODUCTION_ENVIRONMENT:
      return 'production'
    case canopyUrls.STAGING_ENVIRONMENT:
      return 'stage'
    case canopyUrls.INTEGRATION_ENVIRONMENT:
    case canopyUrls.DEVELOPMENT_ENVIRONMENT:
      return 'integ'
    default:
      throw new Error(`Unknown environment '${canopyUrls.getEnvironment()}' for feature toggles`)
  }
}
