import React, { Suspense, useCallback } from 'react'
import styles from './bubbles-list.styles.css'
import moment from 'moment'
import { debounce } from 'lodash'
const CprButton = React.lazy(() => SystemJS.import('canopy-styleguide!sofe').then(sg => Promise.resolve({ default: sg.CprButton })))
const CprIcon = React.lazy(() => SystemJS.import('canopy-styleguide!sofe').then(sg => Promise.resolve({ default: sg.CprIcon })))

export default function IndividualFeatureBubble(props) {
  const { bubble, bottomHR } = props
  let expirationDate
  if (bubble.expirationDate) {
    expirationDate = moment(bubble.expirationDate)
  }
  const isOptOut = bubble.strategy === 'opt-out';

  const daysUntilExpire = expirationDate?.diff(moment(), 'days') || Infinity;

  const learnMoreLink = bubble.documentationUrl ? (
    <a href={bubble.documentationUrl} target="_blank" rel="noopener noreferrer">
      Learn More
    </a>
  ) : null;

  const debouncedUpdate = useCallback(
    debounce((value, days) => bubble.update(value, days), 1000, {
      leading: true,
      trailing: false,
    }),
    []
  );

  return (
    <div className={styles.featureChoice}>
      <Suspense fallback={null}>
        <div className='cps-subheader-sm cps-color-vibrant-ocean cps-margin-bottom-16 cps-padding-right-8'>
          {bubble.title}
        </div>
        {bubble.message ? (
          <div>
            {bubble.message}
          </div>
        ) : (
          <>
            <div className={styles.actionBlock}>
              {
                bubble.active ? (
                  isOptOut ? (
                    <div className={styles.primaryAction}>
                      <div>Delay this feature for</div>
                      <div>
                        {daysUntilExpire < 3 && (
                          <CprButton
                            actionType="unstyled"
                            className={`cps-margin-bottom-8 ${styles.oldVersion}`}
                            onClick={() => debouncedUpdate(false, 1)}
                          >
                            <strong>1 day</strong>
                          </CprButton>
                        )}
                        {daysUntilExpire >= 3 && (
                          <CprButton
                            actionType="unstyled"
                            className={`cps-margin-bottom-8 ${styles.oldVersion}`}
                            onClick={() => debouncedUpdate(false, 3)}
                          >
                            <strong>3 days</strong>
                          </CprButton>
                        )}
                        {daysUntilExpire >= 7 && (
                          <CprButton
                            actionType="unstyled"
                            className={`cps-margin-bottom-8 ${styles.oldVersion}`}
                            onClick={() => debouncedUpdate(false, 7)}
                          >
                            <strong>7 days</strong>
                          </CprButton>
                        )}
                        {daysUntilExpire >= 15 && (
                          <CprButton
                            actionType="unstyled"
                            className={`cps-margin-bottom-8 ${styles.oldVersion}`}
                            onClick={() => debouncedUpdate(false, 15)}
                          >
                            <strong>15 days</strong>
                          </CprButton>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.primaryAction}>
                    <CprButton
                      actionType='primary'
                      className={`cps-margin-bottom-8 ${styles.newVersion}`}
                      onClick={() => debouncedUpdate(false)}
                    >
                      Return to default
                    </CprButton>
                  </div>
                  )
                ) : (
                  <div className={styles.primaryAction}>
                    <CprButton
                      actionType='primary'
                      className={`cps-margin-bottom-8 ${styles.newVersion}`}
                      onClick={() => debouncedUpdate(true)}
                    >
                      Try it out
                    </CprButton>
                  </div>
                )
              }
            </div>
            {
              expirationDate ? (
                isOptOut ? (
                  <div>
                    You can delay this feature, with the option to return to the new
                    experience, until <strong>{expirationDate.format("LL")}</strong>.{" "}
                    {learnMoreLink}
                  </div>
                ) : (
                  <div>
                    You can return to the {bubble.active ? "default" : "current"} experience
                    at anytime until <strong>{expirationDate.format("LL")}</strong>.{" "}
                    {learnMoreLink}
                  </div>
                )
              ) : isOptOut ? (
                <div>
                  This option will only be accessible for a short time. {learnMoreLink}
                </div>
              ) : (
                <div>
                  {bubble.active
                    ? `This option will be available`
                    : `You'll be able to return to the current experience`}{" "}
                  through tax season. {learnMoreLink}
                </div>
              )
            }
          </>
        )}
        {
          bottomHR ? (
            <hr />
          ) : null
        }
      </Suspense>
    </div>
  )
}
