/**
 * define global feature bubbles here that all other services can reference
 * 
 * myFeatureBubble: {
 *   toggle: 'feature_toggle_name',
 *   title: 'My Feature Bubble',
 *   expirationDate: '2020-12-31',
 * }
 */

export const featureBubbleDefinitions = {
}