import { isString, isEmpty, pick, debounce } from 'lodash';
import { captureException } from 'sentry-error-logging!sofe';

import { cachedToggles } from './cached-toggles.js';
import { nameForCurrentEnv } from './env.helpers';
import './opt-in-out/set-up.js';

export { useFeatureBubble } from './opt-in-out/opt-in-out.hook';
export { featureBubble } from './opt-in-out/opt-in-out.js'
export { featureBubbleDefinitions } from './opt-in-out/feature-definitions';

let toggleUsageCache = {};
let toggleUsageTemp = {};
let loggedEmptyToggles = false

export function fetchFeatureToggles(...allArgs) {
  validateToggles()
  const toggleNames = allArgs.filter(arg => typeof arg === 'string')
  return Promise.resolve(pick(cachedToggles, toggleNames));
}

export function featureEnabled(...toggleNames) {
  validateToggles()
  if (toggleNames.length === 0 || toggleNames.find(name => !isString(name) || isEmpty(name))) {
    throw new Error(`featureEnabled must be called with one or more non-empty strings representing the feature name(s)`);
  }

  const allOverrides = getAllLocalStorageFeatureToggles();
  const overrides = toggleNames.reduce((toggleOverrides, toggleName) => {
    if (allOverrides.hasOwnProperty(toggleName)) {
      return {
        ...toggleOverrides,
        [toggleName]: allOverrides[toggleName],
      };
    }
    return toggleOverrides;
  }, {});

  let shouldCallUsage = false;
  toggleNames.forEach((toggle) => {
    if (!toggleUsageCache[toggle] && !toggleUsageTemp[toggle]) {
      toggleUsageTemp[toggle] = toggle;
      shouldCallUsage = true;
    }
  });
  if (shouldCallUsage) {
    debounceToggleUsage();
  }

  const togglesAndOverrides = Object.assign({}, cachedToggles, overrides);
  return toggleNames.some(toggleName =>
    togglesAndOverrides[toggleName]
  );
}

export function getAllLocalStorageFeatureToggles() {
  const featureKeys = Object.keys(window.localStorage).filter(key => key.includes('feature:'))
  return featureKeys.reduce((accumulator, currentValue) => {
    const newString = currentValue.split('feature:')[1]
    const localStorageValue = localStorage.getItem(currentValue)
    if (localStorageValue === 'on' || localStorageValue === 'true' || localStorageValue === 'off' || localStorageValue === 'false') {
      accumulator[newString] = localStorageValue === 'on' || localStorageValue === 'true'
    }
    return accumulator
  }, {});
}

export function getAllToggleInformationForSentry() {
  return { ...cachedToggles }
}

async function postToggleUsage() {
  const toggles = Object.keys(toggleUsageTemp);
  toggles.forEach(toggle => toggleUsageCache[toggle] = toggle);
  toggleUsageTemp = {};

  try {
    return await fetch(`https://tm.canopytax.com/api/toggles/usage`, {
      method: 'POST',
      body: JSON.stringify({
        toggles: {
          features: toggles,
          env: nameForCurrentEnv(),
          user_data: {
            email: window.loggedInUser?.email,
          },
        },
      }),
    });
  } catch (e) {
    captureException?.(e);
  }
}

// Debounce analytics for 1 seconds with a 3 second max wait
const debounceToggleUsage = debounce(postToggleUsage, 1000, {
  maxWait: 1000 * 3,
});

function validateToggles() {
  if (isEmpty(cachedToggles) && !loggedEmptyToggles) {
    loggedEmptyToggles = true
    throw new Error(`Feature toggles have not been fetched`);
  }
}
